import numberFormats from './rules/numbers.ts'

import pt from './locales/pt.json'
import en from './locales/en.json'

const messages = {
  pt,
  en
}

export default defineI18nConfig(() => ({
  legacy: false,
  fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE,
  globalInjection: true,
  messages,
  numberFormats
}))
